:root {
  --ion-color-primary: #0054e9;
  --ion-color-primary-rgb: 0, 84, 233;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #004acd;
  --ion-color-primary-tint: #1a65eb;
  --ion-color-secondary: #0163aa;
  --ion-color-secondary-rgb: 1, 99, 170;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #015796;
  --ion-color-secondary-tint: #1a73b3;
  --ion-color-tertiary: #6030ff;
  --ion-color-tertiary-rgb: 96, 48, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #542ae0;
  --ion-color-tertiary-tint: #7045ff;
  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-medium: #636469;
  --ion-color-medium-rgb: 99, 100, 105;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #57585c;
  --ion-color-medium-tint: #737478;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
}

html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

html.md {
  --ion-default-font: "Roboto", "Helvetica Neue", sans-serif;
}

html {
  --ion-dynamic-font: -apple-system-body;
  --ion-font-family: var(--ion-default-font);
}

body {
  background: var(--ion-background-color);
  color: var(--ion-text-color);
}

body.backdrop-no-scroll {
  overflow: hidden;
}

html.ios ion-modal.modal-card ion-header ion-toolbar:first-of-type, html.ios ion-modal.modal-sheet ion-header ion-toolbar:first-of-type, html.ios ion-modal ion-footer ion-toolbar:first-of-type {
  padding-top: 6px;
}

html.ios ion-modal.modal-card ion-header ion-toolbar:last-of-type, html.ios ion-modal.modal-sheet ion-header ion-toolbar:last-of-type {
  padding-bottom: 6px;
}

html.ios ion-modal ion-toolbar {
  padding-right: calc(var(--ion-safe-area-right) + 8px);
  padding-left: calc(var(--ion-safe-area-left) + 8px);
}

@media screen and (min-width: 768px) {
  html.ios ion-modal.modal-card:first-of-type {
    --backdrop-opacity: 0.18;
  }
}
ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
  --backdrop-opacity: 0;
  --box-shadow: none;
}

html.ios ion-modal.modal-card .ion-page {
  border-top-left-radius: var(--border-radius);
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary, #0054e9) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 0, 84, 233) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-primary-shade, #004acd) !important;
  --ion-color-tint: var(--ion-color-primary-tint, #1a65eb) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary, #0163aa) !important;
  --ion-color-base-rgb: var(--ion-color-secondary-rgb, 1, 99, 170) !important;
  --ion-color-contrast: var(--ion-color-secondary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-secondary-shade, #015796) !important;
  --ion-color-tint: var(--ion-color-secondary-tint, #1a73b3) !important;
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary, #6030ff) !important;
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb, 96, 48, 255) !important;
  --ion-color-contrast: var(--ion-color-tertiary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-tertiary-shade, #542ae0) !important;
  --ion-color-tint: var(--ion-color-tertiary-tint, #7045ff) !important;
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success, #2dd55b) !important;
  --ion-color-base-rgb: var(--ion-color-success-rgb, 45, 213, 91) !important;
  --ion-color-contrast: var(--ion-color-success-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-success-shade, #28bb50) !important;
  --ion-color-tint: var(--ion-color-success-tint, #42d96b) !important;
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning, #ffc409) !important;
  --ion-color-base-rgb: var(--ion-color-warning-rgb, 255, 196, 9) !important;
  --ion-color-contrast: var(--ion-color-warning-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-warning-shade, #e0ac08) !important;
  --ion-color-tint: var(--ion-color-warning-tint, #ffca22) !important;
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger, #c5000f) !important;
  --ion-color-base-rgb: var(--ion-color-danger-rgb, 197, 0, 15) !important;
  --ion-color-contrast: var(--ion-color-danger-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-danger-shade, #ad000d) !important;
  --ion-color-tint: var(--ion-color-danger-tint, #cb1a27) !important;
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium, #636469) !important;
  --ion-color-base-rgb: var(--ion-color-medium-rgb, 99, 100, 105) !important;
  --ion-color-contrast: var(--ion-color-medium-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-medium-shade, #57585c) !important;
  --ion-color-tint: var(--ion-color-medium-tint, #737478) !important;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark, #222428) !important;
  --ion-color-base-rgb: var(--ion-color-dark-rgb, 34, 36, 40) !important;
  --ion-color-contrast: var(--ion-color-dark-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb, 255, 255, 255) !important;
  --ion-color-shade: var(--ion-color-dark-shade, #1e2023) !important;
  --ion-color-tint: var(--ion-color-dark-tint, #383a3e) !important;
}

.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  z-index: 0;
}

ion-modal > .ion-page {
  position: relative;
  contain: layout style;
  height: 100%;
}

.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}

ion-route, ion-route-redirect, ion-router, ion-select-option, ion-nav-controller, ion-menu-controller, ion-action-sheet-controller, ion-alert-controller, ion-loading-controller, ion-modal-controller, ion-picker-controller, ion-popover-controller, ion-toast-controller, .ion-page-hidden {
  display: none !important;
}

.ion-page-invisible {
  opacity: 0;
}

.can-go-back > ion-header ion-back-button {
  display: block;
}

html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
ion-card.ion-color .ion-inherit-color, ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}

.menu-content {
  transform: translate3d(0, 0, 0);
}

.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
  overflow-y: hidden;
}

.menu-content-open ion-content {
  --overflow: hidden;
}

.menu-content-open .ion-content-scroll-host {
  overflow: hidden;
}

.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}

[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}

.md .menu-content-reveal {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

.md .menu-content-push {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

ion-accordion-group.accordion-group-expand-inset > ion-accordion:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

ion-accordion-group.accordion-group-expand-inset > ion-accordion:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

ion-accordion-group > ion-accordion:last-of-type ion-item[slot=header] {
  --border-width: 0px;
}

ion-accordion.accordion-animated > [slot=header] .ion-accordion-toggle-icon {
  transition: 300ms transform cubic-bezier(0.25, 0.8, 0.5, 1);
}

@media (prefers-reduced-motion: reduce) {
  ion-accordion .ion-accordion-toggle-icon {
    transition: none !important;
  }
}
ion-accordion.accordion-expanding > [slot=header] .ion-accordion-toggle-icon, ion-accordion.accordion-expanded > [slot=header] .ion-accordion-toggle-icon {
  transform: rotate(180deg);
}

ion-accordion-group.accordion-group-expand-inset.md > ion-accordion.accordion-previous ion-item[slot=header] {
  --border-width: 0px;
  --inner-border-width: 0px;
}

ion-accordion-group.accordion-group-expand-inset.md > ion-accordion.accordion-expanding:first-of-type, ion-accordion-group.accordion-group-expand-inset.md > ion-accordion.accordion-expanded:first-of-type {
  margin-top: 0;
}

ion-input input::-webkit-date-and-time-value {
  text-align: start;
}

.ion-datetime-button-overlay {
  --width: fit-content;
  --height: fit-content;
}

.ion-datetime-button-overlay ion-datetime.datetime-grid {
  width: 320px;
  min-height: 320px;
}

[ion-last-focus], header[tabindex="-1"]:focus, [role=banner][tabindex="-1"]:focus, main[tabindex="-1"]:focus, [role=main][tabindex="-1"]:focus, h1[tabindex="-1"]:focus, [role=heading][aria-level="1"][tabindex="-1"]:focus {
  outline: none;
}

.popover-viewport:has(> ion-content) {
  overflow: hidden;
}

@supports not selector(:has(> ion-content)) {
  .popover-viewport {
    overflow: hidden;
  }
} 
audio, canvas, progress, video {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

b, strong {
  font-weight: bold;
}

img {
  max-width: 100%;
}

hr {
  height: 1px;
  border-width: 0;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

label, input, select, textarea {
  font-family: inherit;
  line-height: normal;
}

textarea {
  overflow: auto;
  height: auto;
  font: inherit;
  color: inherit;
}

textarea::placeholder {
  padding-left: 2px;
}

form, input, optgroup, select {
  margin: 0;
  font: inherit;
  color: inherit;
}

html input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

a, a div, a span, a ion-icon, a ion-label, button, button div, button span, button ion-icon, button ion-label, .ion-tappable, [tappable], [tappable] div, [tappable] span, [tappable] ion-icon, [tappable] ion-label, input, textarea {
  touch-action: manipulation;
}

a ion-label, button ion-label {
  pointer-events: none;
}

button {
  padding: 0;
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  line-height: 1;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
}

[tappable] {
  cursor: pointer;
}

a[disabled], button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
} 
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html:not(.hydrated) body {
  display: none;
}

html.ion-ce body {
  display: block;
}

html.plt-pwa {
  height: 100vh;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transform: translateZ(0);
  text-rendering: optimizeLegibility;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  overscroll-behavior-y: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
} 
html {
  font-family: var(--ion-font-family);
}

@supports (-webkit-touch-callout: none) {
  html {
    font: var(--ion-dynamic-font, 16px var(--ion-font-family));
  }
}
a {
  background-color: transparent;
  color: var(--ion-color-primary, #0054e9);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  margin-top: 20px;
  font-size: 1.625rem;
}

h2 {
  margin-top: 18px;
  font-size: 1.5rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

small {
  font-size: 75%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
} 
* {
  font-size: 16px;
  letter-spacing: -0.4px;
  --border: none !important;
}
* big {
  font-size: 1rem !important;
  vertical-align: top;
}
* sup {
  font-size: 0.9rem !important;
  font-weight: normal !important;
  top: 10px !important;
  vertical-align: top;
}
* small {
  font-size: 0.75rem !important;
  vertical-align: inherit !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: linear-gradient(var(--ion-color-primary-dark), var(--ion-color-primary));
}

ul {
  color: var(--ion-color-dark);
}

[hidden] {
  display: none !important;
}

ion-content {
  --background: var(--ion-color-background);
}

ion-back-button {
  --color: var(--ion-color-always-white);
}

ion-button {
  text-transform: none;
  border-radius: 0.625rem;
  min-height: unset !important;
}

ion-button.button-has-icon-only {
  min-width: 0 !important;
  min-height: 0 !important;
}

ion-card {
  background: var(--ion-color-white);
  margin: 0.5rem;
  padding: 0;
  border-radius: 0.625rem;
  overflow: hidden;
}

ion-card-content {
  padding: 0.5rem 1rem !important;
}

ion-card-header {
  font-size: 1.1rem;
  font-weight: bold;
  --color: var(--ion-color-secondary-contrast);
  --background: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem;
}

ion-chip {
  min-height: unset;
}

ion-icon {
  color: var(--ion-color-dark);
}

ion-input {
  min-height: 66.5px !important;
  color: var(--ion-color-dark) !important;
  --highlight-color-focused: var(--ion-color-dark) !important;
  --highlight-color-valid: var(--ion-color-dark) !important;
  --highlight-color-invalid: var(--ion-color-dark) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

ion-item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --color: var(--ion-color-white);
  --ion-background-color: var(--ion-color-white);
  --background-focused: var(--ion-color-white);
  --ion-item-border-color: var(--ion-color-gray-50);
}
ion-item .ion-valid {
  --highlight-color-valid: var(--ion-color-dark) !important;
}

ion-label {
  color: var(--ion-color-dark) !important;
  white-space: normal !important;
}

.label-stacked.sc-ion-label-md-h {
  font-size: 0.8rem !important;
  -webkit-transform: translateY(30%) !important;
  transform: translateY(30%) !important;
}

ion-list {
  background: var(--ion-color-white);
}

ion-loading.loading-message .loading-wrapper {
  border-radius: 0.5rem !important;
}

ion-modal {
  --max-height: 90% !important;
  --width: 34rem !important;
  --max-width: 90% !important;
  --border-radius: 1rem;
}

.popover-on-top {
  z-index: 99999 !important;
}

ion-progress-bar {
  --background: var(--ion-color-dark);
  --progress-background: var(--ion-color-dark-contrast);
}

ion-radio {
  color: var(--ion-color-dark) !important;
  --color-checked: var(--ion-color-dark);
}

ion-searchbar {
  padding: 0 !important;
  --box-shadow: none !important;
}

ion-searchbar .searchbar-input-container .searchbar-input {
  font-size: 1rem;
  height: 36px !important;
  --border-radius: 6px;
  --placeholder-color: var(--ion-color-always-dark);
  --background: var(--ion-color-always-light);
  padding-inline-start: 3rem !important;
}

ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md {
  top: 7px;
}

ion-searchbar .searchbar-clear-icon {
  color: var(--ion-color-always-dark);
}

ion-segment {
  --background: var(--ion-color-segment);
}

ion-segment-button {
  min-width: 0;
  color: var(--ion-color-always-black);
  --indicator-color: var(--ion-color-always-white);
  --background: var(--ion-color-segment);
  margin: 3px 1px;
}
ion-segment-button ion-label {
  color: var(--ion-color-always-black) !important;
}

ion-select {
  color: var(--ion-color-dark);
  height: 4rem !important;
}

ion-select::part(icon) {
  color: var(--ion-color-dark);
  right: 0.5rem;
  top: 0.75rem;
}

ion-tab-bar {
  --background: var(--ion-color-primary);
  --border: 0;
}

ion-tab-button {
  --color: var(--ion-color-primary);
  --color-selected: var(--ion-color-primary-dark);
}

ion-textarea {
  color: var(--ion-color-dark) !important;
  --highlight-color-focused: var(--ion-color-dark) !important;
  --highlight-color-valid: var(--ion-color-dark) !important;
  --highlight-color-invalid: var(--ion-color-dark) !important;
}

ion-title {
  color: var(--ion-color-primary-contrast);
  padding-inline: 1rem;
  font-size: 1.3rem;
}

ion-toggle {
  padding-left: 1rem;
  padding-top: 4px;
  --track-background-checked: var(--ion-color-light-green);
  --handle-background-checked: var(--ion-color-green);
  --track-background: var(--ion-color-negative-light);
}

ion-toolbar {
  --color: var(--ion-color-primary-contrast);
}

.ion-color-neutral {
  --ion-color-base: var(--ion-color-neutral) !important;
}

.custom-loader {
  border: 0.5rem solid var(--ion-color-primary);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.custom-left-justify {
  text-align: left !important;
}

.swal2-modal {
  padding-left: 0;
  padding-right: 0;
  background-color: var(--ion-color-always-white) !important;
  border: 3px solid var(--ion-color-accent) !important;
  border-radius: 1rem !important;
}
.swal2-modal .swal2-title {
  font-size: 1.3rem !important;
}
.swal2-modal .swal2-icon {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-line-tip] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-circular-line-left] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-line-long] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-ring] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-fix] {
  font-size: 0.65rem !important;
}
.swal2-modal .swal2-icon.swal2-success [class^=swal2-success-circular-line-right] {
  font-size: 0.65rem !important;
}

.swal2-container {
  width: auto !important;
  background: rgba(255, 255, 255, 0.8);
}

.swal2-loading {
  max-width: 18rem !important;
}

.swal2-html-container b {
  font-size: 18px !important;
}

.swal2-styled.swal2-cancel {
  height: 2.4rem;
  padding: 0.6375rem 1rem !important;
  color: var(--ion-color-accent) !important;
  background-color: var(--ion-color-always-white) !important;
  border-width: 2px !important;
  border-color: var(--ion-color-accent) !important;
  border-style: solid !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(117, 218, 173, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes fade {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

:root {
  --shared-font-family: "Open Sans", sans-serif;
  --shared-tracking: -0.4px;
  --mat-sys-body-large-font: var(--shared-font-family);
  --mat-sys-body-medium-font: var(--shared-font-family);
  --mat-sys-body-small-font: var(--shared-font-family);
  --mat-app-body-large-font: var(--shared-font-family);
  --mat-app-body-medium-font: var(--shared-font-family);
  --mat-app-body-small-font: var(--shared-font-family);
  --mat-sys-title-large-font: var(--shared-font-family);
  --mat-sys-title-medium-font: var(--shared-font-family);
  --mat-sys-title-small-font: var(--shared-font-family);
  --mat-app-title-large-font: var(--shared-font-family);
  --mat-app-title-medium-font: var(--shared-font-family);
  --mat-app-title-small-font: var(--shared-font-family);
  --mat-sys-body-large-tracking: var(--shared-tracking);
  --mat-sys-body-medium-tracking: var(--shared-tracking);
  --mat-sys-body-small-tracking: var(--shared-tracking);
  --mat-app-body-large-tracking: var(--shared-tracking);
  --mat-app-body-medium-tracking: var(--shared-tracking);
  --mat-app-body-small-tracking: var(--shared-tracking);
  --mdc-theme-primary: var(--ion-color-primary);
  --mdc-theme-secondary: var(--ion-color-secondary);
  --mdc-theme-accent: var(--ion-color-accent);
  --mat-datepicker-calendar-header-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--ion-color-gray-50);
  --mat-datepicker-calendar-period-button-icon-color: var(--ion-color-dark);
  --mat-expansion-header-expanded-state-height: auto;
  --mat-expansion-header-indicator-color: var(--ion-color-dark);
  --mdc-icon-button-disabled-icon-color: var(--ion-color-gray-50);
  --mat-menu-item-leading-spacing: 0 !important;
  --mat-menu-item-trailing-spacing: 0 !important;
  --mat-select-enabled-trigger-text-color: var(--ion-color-dark);
  --mat-select-disabled-trigger-text-color: var(--ion-color-gray-50);
}

body.dark {
  --mat-datepicker-calendar-header-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-text-color: var(--ion-color-dark);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--ion-color-gray-50);
  --mat-datepicker-calendar-period-button-icon-color: var(--ion-color-dark);
  --mat-expansion-header-indicator-color: var(--ion-color-dark);
  --mdc-icon-button-disabled-icon-color: var(--ion-color-gray-50);
  --mat-select-enabled-trigger-text-color: var(--ion-color-dark);
  --mat-select-disabled-trigger-text-color: var(--ion-color-gray-50);
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  color: var(--ion-color-accent-contrast) !important;
  background-color: var(--ion-color-accent) !important;
  border-color: var(--ion-color-accent) !important;
}

.mat-mdc-chip.mat-mdc-chip-option {
  height: 32px;
  margin: 4px !important;
}

.mat-mdc-chip-action-label {
  font-size: 0.875rem !important;
}

.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-selected {
  color: transparent !important;
  background: linear-gradient(var(--ion-color-chip-selected-light), var(--ion-color-chip-selected));
}
.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-selected .mat-mdc-chip-action-label {
  color: var(--ion-color-chip-selected-contrast) !important;
}

.mat-mdc-chip.mat-mdc-chip-option.mat-primary:not(.mat-mdc-chip-selected) {
  color: transparent !important;
  background: linear-gradient(var(--ion-color-chip-light), var(--ion-color-chip));
}
.mat-mdc-chip.mat-mdc-chip-option.mat-primary:not(.mat-mdc-chip-selected) .mat-mdc-chip-action-label {
  color: var(--ion-color-chip-contrast) !important;
}

.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-disabled {
  background: var(--ion-color-chip-disabled) !important;
  border: 1px solid var(--ion-color-chip-disabled-light) !important;
}
.mat-mdc-chip.mat-mdc-chip-option.mat-primary.mat-mdc-chip-disabled .mat-mdc-chip-action-label {
  color: var(--ion-color-chip-disabled-contrast) !important;
}

.mat-mdc-chip-graphic {
  width: 0 !important;
}

.mdc-evolution-chip__checkmark {
  display: none !important;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
}

.mat-calendar-body-cell-content {
  border-width: 0 !important;
}

.mat-calendar-body-selected {
  color: var(--ion-color-accent-contrast) !important;
  background-color: var(--ion-color-accent) !important;
}

.mat-calendar-controls {
  margin-top: 0 !important;
}

.mat-expansion-indicator svg {
  color: var(--ion-color-dark);
  width: 2.2rem !important;
  height: 3rem !important;
  margin-top: 4px !important;
}

.mat-mdc-expansion-indicator::after {
  margin-top: 1.75rem !important;
  padding: 6px !important;
  transform-origin: 180% 50%;
  color: var(--ion-color-always-black);
}

.mat-accordion .mat-expansion-panel {
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.mat-expansion-panel .mat-expansion-panel-header {
  height: auto;
  padding: 0.5rem 1rem;
  background: var(--ion-color-white) !important;
  border-radius: 0.5rem 0 0 0;
  display: flex;
  align-items: flex-start;
}

.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  background: var(--ion-color-white) !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-menu-item-text {
  color: var(--ion-color-dark);
}

.mat-mdc-menu-panel {
  background-color: var(--ion-color-background) !important;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 1rem !important;
}
.mat-mdc-menu-panel ion-icon {
  margin-right: 0.5rem;
}

.mat-mdc-menu-panel .mat-pseudo-checkbox {
  display: none;
}

.short-menu {
  height: 14.5rem !important;
}

.mat-mdc-paginator {
  background-color: var(--ion-color-white) !important;
}
.mat-mdc-paginator .mat-mdc-paginator-container {
  min-height: 40px !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

.mdc-line-ripple {
  color: var(--ion-color-gray-50) !important;
}

.mdc-list-item__primary-text {
  width: 100%;
}

mat-select-trigger,
.mat-mdc-select-value-text,
.mat-mdc-select-min-line,
.mdc-list-item__primary-text {
  font-size: 0.9rem !important;
}

.mat-mdc-select-panel {
  background-color: var(--ion-color-background) !important;
  max-height: 50vh !important;
}

.mat-mdc-select-panel .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-option {
  color: var(--ion-color-dark) !important;
}

.mdc-text-field {
  font-size: 0.875rem !important;
  padding: 0 !important;
}

.mdc-text-field--filled {
  height: 2rem !important;
}

.mat-mdc-form-field-infix {
  padding: 0.25rem 0 0 0 !important;
}

.mat-sort-header-arrow {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mat-mdc-table {
  background-color: transparent !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  padding: 0 !important;
  color: var(--ion-color-dark);
}

.mat-mdc-tooltip {
  white-space: pre-line;
  max-width: 15rem;
}

.cdk-overlay-pane.mat-mdc-tooltip-panel .mat-mdc-tooltip {
  color: var(--ion-color-dark-contrast);
  background-color: rgba(var(--ion-color-dark-rgb), 0.8);
  font-size: 0.8rem;
}

:root {
  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;
  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;
  --ion-font-family: "Open Sans", sans-serif;
  --ion-margin: var(--app-fair-margin);
  --ion-color-primary: #8b0000;
  --ion-color-primary-dark: #660000;
  --ion-color-primary-light: #a30000;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-accent: #008b8b;
  --ion-color-accent-light: #00a3a3;
  --ion-color-accent-dark: #006f6f;
  --ion-color-accent-contrast: #ffffff;
  --ion-color-secondary: #262626;
  --ion-color-secondary-light: #404040;
  --ion-color-secondary-dark: #0d0d0d;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-warning: #ffa726;
  --ion-color-warning-contrast: #000000;
  --ion-color-danger: #e12d39;
  --ion-color-dark: #262626;
  --ion-color-dark-light: #0d0d0d;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-rgb: 38, 38, 38;
  --ion-color-light: var(--ion-color-gray-15);
  --ion-color-light-light: var(--ion-color-gray-10);
  --ion-color-light-contrast: var(--ion-color-black);
  --ion-color-positive: #4daf1b;
  --ion-color-positive-light: #5dd421;
  --ion-color-positive-arrow: #80e34f;
  --ion-color-negative: #e12d39;
  --ion-color-negative-light: #f7c9cd;
  --ion-color-negative-background: #e6e6e6;
  --ion-color-negative-arrow: #ff4652;
  --ion-color-neutral: #f4f4f4;
  --ion-color-drilldown: #ffffdc;
  --ion-color-black: #000000;
  --ion-color-gray-95: #0d0d0d;
  --ion-color-gray-90: #1a1a1a;
  --ion-color-gray-85: #262626;
  --ion-color-gray-80: #333333;
  --ion-color-gray-75: #404040;
  --ion-color-gray-70: #4d4d4d;
  --ion-color-gray-65: #595959;
  --ion-color-gray-60: #666666;
  --ion-color-gray-55: #737373;
  --ion-color-gray-50: #808080;
  --ion-color-gray-45: #8c8c8c;
  --ion-color-gray-40: #999999;
  --ion-color-gray-35: #a6a6a6;
  --ion-color-gray-30: #b3b3b3;
  --ion-color-gray-25: #bfbfbf;
  --ion-color-gray-20: #cccccc;
  --ion-color-gray-15: #d9d9d9;
  --ion-color-gray-10: #e6e6e6;
  --ion-color-gray-05: #f2f2f2;
  --ion-color-gray-01: #f9f9f9;
  --ion-color-white: #ffffff;
  --ion-color-chip: var(--ion-color-gray-15);
  --ion-color-chip-light: var(--ion-color-gray-10);
  --ion-color-chip-contrast: var(--ion-color-black);
  --ion-color-chip-selected: var(--ion-color-black);
  --ion-color-chip-selected-light: var(--ion-color-gray-85);
  --ion-color-chip-selected-contrast: var(--ion-color-white);
  --ion-color-chip-disabled: var(--ion-color-gray-10);
  --ion-color-chip-disabled-light: var(--ion-color-gray-15);
  --ion-color-chip-disabled-contrast: var(--ion-color-gray-35);
  --ion-color-segment: #f2f2f2;
  --ion-color-calendar-background: #ffffff;
  --ion-color-background: #f2f2f2;
  --ion-color-background-light: #fbfbfb;
  --ion-color-divider: #c8c8c8;
  --ion-background-color: var(--ion-color-white);
  --ion-tab-bar-background: var(--ion-color-white);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);
  --ion-color-always-black: #000000;
  --ion-color-always-dark: #262626;
  --ion-color-always-light: #ededed;
  --ion-color-always-light-light: #f2f2f2;
  --ion-color-always-white: #ffffff;
  --ion-color-green: #008000;
  --ion-color-light-green: #c6e6e1;
  --ion-color-mint-green: #75daad;
  --ion-color-aqua: #54c0eb;
  --ion-color-facebook: #4267b2;
  --ion-color-google: #4285f4;
}

/** Dark Mode Colors **/
body.dark {
  --ion-color-accent: #00a3a3;
  --ion-color-accent-light: #00c4c4;
  --ion-color-accent-dark: #008b8b;
  --ion-color-accent-contrast: #ffffff;
  --ion-color-secondary: #737373;
  --ion-color-secondary-light: #666666;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-warning: #ffce00;
  --ion-color-warning-contrast: #000000;
  --ion-color-dark: #ffffff;
  --ion-color-dark-light: #e6e6e6;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-rgb: 255, 255, 255;
  --ion-color-light: var(--ion-color-gray-15);
  --ion-color-light-light: var(--ion-color-gray-10);
  --ion-color-light-contrast: var(--ion-color-black);
  --ion-color-black: #ffffff;
  --ion-color-gray-95: #f5f5f5;
  --ion-color-gray-90: #ececec;
  --ion-color-gray-85: #e2e2e2;
  --ion-color-gray-80: #d9d9d9;
  --ion-color-gray-75: #cfcfcf;
  --ion-color-gray-70: #c6c6c6;
  --ion-color-gray-65: #bcbcbc;
  --ion-color-gray-60: #b2b2b2;
  --ion-color-gray-55: #a9a9a9;
  --ion-color-gray-50: #a0a0a0;
  --ion-color-gray-45: #969696;
  --ion-color-gray-40: #8c8c8c;
  --ion-color-gray-35: #828282;
  --ion-color-gray-30: #7a7a7a;
  --ion-color-gray-25: #707070;
  --ion-color-gray-20: #666666;
  --ion-color-gray-15: #5c5c5c;
  --ion-color-gray-10: #535353;
  --ion-color-gray-05: #4a4a4a;
  --ion-color-gray-01: #1f1f1f;
  --ion-color-white: #000000;
  --ion-color-chip: var(--ion-color-gray-45);
  --ion-color-chip-light: var(--ion-color-gray-40);
  --ion-color-chip-contrast: var(--ion-color-black);
  --ion-color-chip-selected: var(--ion-color-black);
  --ion-color-chip-selected-light: var(--ion-color-gray-95);
  --ion-color-chip-selected-contrast: var(--ion-color-white);
  --ion-color-chip-disabled: var(--ion-color-gray-20);
  --ion-color-chip-disabled-light: var(--ion-color-gray-30);
  --ion-color-chip-disabled-contrast: var(--ion-color-gray-45);
  --ion-color-segment: #cccccc;
  --ion-color-calendar-background: #424242;
  --ion-color-background: #424242;
  --ion-color-background-light: #4d4d4d;
  --ion-color-divider: #999999;
  --ion-background-color: var(--ion-color-white);
  --ion-tab-bar-background: var(--ion-color-white);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);
}

#splash {
  z-index: 1;
  position: fixed;
  top: calc(50% - 5.5rem);
  left: calc(50% - 5rem);
}

.splash-logo {
  width: 10rem;
}

@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v40-latin-regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v40-latin-italic.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v40-latin-700.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v40-latin-700italic.woff2") format("woff2");
}