:root {
  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;

  --ion-font-family: "Open Sans", sans-serif;
  --ion-margin: var(--app-fair-margin);

  --ion-color-primary: #8b0000;
  --ion-color-primary-dark: #660000;
  --ion-color-primary-light: #a30000;
  --ion-color-primary-contrast: #ffffff;

  --ion-color-accent: #008b8b;
  --ion-color-accent-light: #00a3a3;
  --ion-color-accent-dark: #006f6f;
  --ion-color-accent-contrast: #ffffff;

  --ion-color-secondary: #262626;
  --ion-color-secondary-light: #404040;
  --ion-color-secondary-dark: #0d0d0d;
  --ion-color-secondary-contrast: #ffffff;

  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;

  --ion-color-warning: #ffa726;
  --ion-color-warning-contrast: #000000;

  --ion-color-danger: #e12d39;

  --ion-color-dark: #262626;
  --ion-color-dark-light: #0d0d0d;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-rgb: 38, 38, 38;

  --ion-color-light: var(--ion-color-gray-15);
  --ion-color-light-light: var(--ion-color-gray-10);
  --ion-color-light-contrast: var(--ion-color-black);

  --ion-color-positive: #4daf1b;
  --ion-color-positive-light: #5dd421;
  --ion-color-positive-arrow: #80e34f;
  --ion-color-negative: #e12d39;
  --ion-color-negative-light: #f7c9cd;
  --ion-color-negative-background: #e6e6e6;
  --ion-color-negative-arrow: #ff4652;
  --ion-color-neutral: #f4f4f4;
  --ion-color-drilldown: #ffffdc;

  --ion-color-black: #000000;
  --ion-color-gray-95: #0d0d0d;
  --ion-color-gray-90: #1a1a1a;
  --ion-color-gray-85: #262626;
  --ion-color-gray-80: #333333;
  --ion-color-gray-75: #404040;
  --ion-color-gray-70: #4d4d4d;
  --ion-color-gray-65: #595959;
  --ion-color-gray-60: #666666;
  --ion-color-gray-55: #737373;
  --ion-color-gray-50: #808080;
  --ion-color-gray-45: #8c8c8c;
  --ion-color-gray-40: #999999;
  --ion-color-gray-35: #a6a6a6;
  --ion-color-gray-30: #b3b3b3;
  --ion-color-gray-25: #bfbfbf;
  --ion-color-gray-20: #cccccc;
  --ion-color-gray-15: #d9d9d9;
  --ion-color-gray-10: #e6e6e6;
  --ion-color-gray-05: #f2f2f2;
  --ion-color-gray-01: #f9f9f9;
  --ion-color-white: #ffffff;

  --ion-color-chip: var(--ion-color-gray-15);
  --ion-color-chip-light: var(--ion-color-gray-10);
  --ion-color-chip-contrast: var(--ion-color-black);
  --ion-color-chip-selected: var(--ion-color-black);
  --ion-color-chip-selected-light: var(--ion-color-gray-85);
  --ion-color-chip-selected-contrast: var(--ion-color-white);
  --ion-color-chip-disabled: var(--ion-color-gray-10);
  --ion-color-chip-disabled-light: var(--ion-color-gray-15);
  --ion-color-chip-disabled-contrast: var(--ion-color-gray-35);

  --ion-color-segment: #f2f2f2;
  --ion-color-calendar-background: #ffffff;
  --ion-color-background: #f2f2f2;
  --ion-color-background-light: #fbfbfb;
  --ion-color-divider: #c8c8c8;

  --ion-background-color: var(--ion-color-white);

  --ion-tab-bar-background: var(--ion-color-white);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);

  --ion-color-always-black: #000000;
  --ion-color-always-dark: #262626;
  --ion-color-always-light: #ededed;
  --ion-color-always-light-light: #f2f2f2;
  --ion-color-always-white: #ffffff;

  --ion-color-green: #008000;
  --ion-color-light-green: #c6e6e1;
  --ion-color-mint-green: #75daad;
  --ion-color-aqua: #54c0eb;

  --ion-color-facebook: #4267b2;
  --ion-color-google: #4285f4;
}

/** Dark Mode Colors **/

body.dark {
  --ion-color-accent: #00a3a3;
  --ion-color-accent-light: #00c4c4;
  --ion-color-accent-dark: #008b8b;
  --ion-color-accent-contrast: #ffffff;

  --ion-color-secondary: #737373;
  --ion-color-secondary-light: #666666;
  --ion-color-secondary-contrast: #ffffff;

  --ion-color-tertiary: #d11345;
  --ion-color-tertiary-contrast: #ffffff;

  --ion-color-warning: #ffce00;
  --ion-color-warning-contrast: #000000;

  --ion-color-dark: #ffffff;
  --ion-color-dark-light: #e6e6e6;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-rgb: 255, 255, 255;

  --ion-color-light: var(--ion-color-gray-15);
  --ion-color-light-light: var(--ion-color-gray-10);
  --ion-color-light-contrast: var(--ion-color-black);

  --ion-color-black: #ffffff;
  --ion-color-gray-95: #f5f5f5;
  --ion-color-gray-90: #ececec;
  --ion-color-gray-85: #e2e2e2;
  --ion-color-gray-80: #d9d9d9;
  --ion-color-gray-75: #cfcfcf;
  --ion-color-gray-70: #c6c6c6;
  --ion-color-gray-65: #bcbcbc;
  --ion-color-gray-60: #b2b2b2;
  --ion-color-gray-55: #a9a9a9;
  --ion-color-gray-50: #a0a0a0;
  --ion-color-gray-45: #969696;
  --ion-color-gray-40: #8c8c8c;
  --ion-color-gray-35: #828282;
  --ion-color-gray-30: #7a7a7a;
  --ion-color-gray-25: #707070;
  --ion-color-gray-20: #666666;
  --ion-color-gray-15: #5c5c5c;
  --ion-color-gray-10: #535353;
  --ion-color-gray-05: #4a4a4a;
  --ion-color-gray-01: #1f1f1f;
  --ion-color-white: #000000;

  --ion-color-chip: var(--ion-color-gray-45);
  --ion-color-chip-light: var(--ion-color-gray-40);
  --ion-color-chip-contrast: var(--ion-color-black);
  --ion-color-chip-selected: var(--ion-color-black);
  --ion-color-chip-selected-light: var(--ion-color-gray-95);
  --ion-color-chip-selected-contrast: var(--ion-color-white);
  --ion-color-chip-disabled: var(--ion-color-gray-20);
  --ion-color-chip-disabled-light: var(--ion-color-gray-30);
  --ion-color-chip-disabled-contrast: var(--ion-color-gray-45);

  --ion-color-segment: #cccccc;
  --ion-color-calendar-background: #424242;
  --ion-color-background: #424242;
  --ion-color-background-light: #4d4d4d;
  --ion-color-divider: #999999;

  --ion-background-color: var(--ion-color-white);
  --ion-tab-bar-background: var(--ion-color-white);
  --ion-tab-bar-border-color: var(--ion-color-primary);
  --ion-tab-bar-color-activated: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-tertiary);
}
